<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="800"
      @click:outside="reset"
    >
      <v-card>
        <v-card-title class="headline">
          {{ isEditing ? "Edit" : "Add a new" }} Event
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
          <v-form @submit.prevent="save" method="post" id="event-form">
            <v-select
              label="Event Type *"
              :items="types"
              v-model="fields.event_type"
              outlined
              background-color="white"
              :error="errors.hasOwnProperty('event_type')"
              :error-messages="errors['event_type']"
            ></v-select>
            <div v-if="fields.event_type !== null">
              <v-text-field
                label="Name *"
                v-model="fields.name"
                type="text"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('name')"
                :error-messages="errors['name']"
              ></v-text-field>

              <v-text-field
                v-if="isEditing"
                label="Slug *"
                v-model="fields.slug"
                type="text"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('slug')"
                :error-messages="errors['slug']"
              ></v-text-field>

              <v-text-field
                v-else
                label="Slug *"
                v-model="slug"
                type="text"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('slug')"
                :error-messages="errors['slug']"
              ></v-text-field>

              <v-text-field
                label="Start Date*"
                v-model="fields.start_date"
                type="date"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('start_date')"
                :error-messages="errors['start_date']"
              ></v-text-field>
              <v-text-field
                label="Start Time"
                v-model="fields.start_time"
                type="time"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('start_time')"
                :error-messages="errors['start_time']"
              ></v-text-field>
              <v-text-field
                label="End Date*"
                v-model="fields.end_date"
                type="date"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('end_date')"
                :error-messages="errors['end_date']"
              ></v-text-field>
              <v-text-field
                label="End Time"
                v-model="fields.end_time"
                type="time"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('end_time')"
                :error-messages="errors['end_time']"
              ></v-text-field>

              <v-text-field
                label="Location"
                v-model="fields.location"
                type="text"
                v-if="fields.event_type == 'christmas'"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('location')"
                :error-messages="errors['location']"
              ></v-text-field>

              <v-switch
                label="Feature This Event?"
                v-model="fields.is_featured"
                inset
                color="green"
              ></v-switch>
              <v-switch
                label="Event To Be Ticketed?"
                v-model="fields.is_ticketed"
                v-if="fields.event_type !== 'christmas'"
                inset
                color="green"
              ></v-switch>
              <v-switch
                label="Will Tickets Cost?"
                v-model="fields.is_paid"
                v-if="fields.is_ticketed && fields.event_type !== 'christmas'"
                inset
                color="green"
              ></v-switch>
              <v-text-field
                label="Under 3s Ticket Price *"
                v-model="fields.ticket_price_under_3"
                v-if="fields.is_paid && fields.event_type !== 'christmas'"
                type="number"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('ticket_price_under_3')"
                :error-messages="errors['ticket_price_under_3']"
              ></v-text-field>
              <v-text-field
                label="Over 3s Ticket Price *"
                v-model="fields.ticket_price_over_3"
                v-if="fields.is_paid && fields.event_type !== 'christmas'"
                type="number"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('ticket_price_over_3')"
                :error-messages="errors['ticket_price_over_3']"
              ></v-text-field>
              <v-text-field
                label="Ticket Price *"
                v-model="fields.ticket_price"
                v-if="fields.event_type === 'christmas'"
                type="number"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('ticket_price')"
                :error-messages="errors['ticket_price']"
              ></v-text-field>
              <v-card
                outlined
                class="mb-6"
                v-if="fields.event_type === 'christmas'"
              >
                <v-card-subtitle class="d-flex align-center">
                  <div>Time Blocks</div>
                  <v-spacer></v-spacer>
                  <v-btn
                    x-small
                    depressed
                    color="accent"
                    @click="$refs.timeBlockDialog.open()"
                    ><v-icon left>mdi-plus</v-icon> Add Time Block</v-btn
                  >
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Date</th>
                        <th class="text-left">Start Time</th>
                        <th class="text-left">End Time</th>
                        <th class="text-left">Allocation</th>
                        <th class="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(time_block, key) in fields.time_blocks"
                        :key="key"
                      >
                        <td v-html="formatDateTime(time_block.date)" />
                        <td v-html="time_block.start_time" />
                        <td v-html="time_block.end_time" />
                        <td v-html="time_block.allocation" />
                        <td class="text-right">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                x-small
                                depressed
                                color="blue lighten-4 blue--text"
                                v-on="on"
                                class="mr-2"
                                @click="
                                  $refs.timeBlockDialog.open(time_block, key)
                                "
                              >
                                <v-icon x-small>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                x-small
                                depressed
                                color="red lighten-4 red--text"
                                v-on="on"
                                @click="deleteTimeBlock(key)"
                              >
                                <v-icon x-small>mdi-archive</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
              <v-text-field
                label="Total Tickets Available *"
                v-model="fields.allocation"
                v-if="
                  fields.has_allocation &&
                  fields.event_type !== 'christmas' &&
                  fields.event_type !== 'free'
                "
                type="number"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('allocation')"
                :error-messages="errors['allocation']"
              ></v-text-field>
              <v-textarea
                label="Short Description"
                v-model="fields.short_description"
                counter
                outlined
                :rules="[(v) => v.length <= 500 || 'Max 500 characters']"
                background-color="white"
                :error="errors.hasOwnProperty('short_description')"
                :error-messages="errors['short_description']"
              ></v-textarea>
              <tiptap
                v-model="fields.description"
                label="Event Description"
                class="mb-8"
              />
              <v-file-input
                label="Event Image*"
                v-model="fields.main_image"
                accept="image/png, image/jpeg"
                placeholder="Pick an image"
                prepend-icon="mdi-camera"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('main_image')"
                :error-messages="errors['main_image']"
              ></v-file-input>

              <v-switch
                label="Draft"
                v-model="fields.draft"
                inset
                :error="errors.hasOwnProperty('draft')"
                :error-messages="errors['draft']"
              ></v-switch>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="reset()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            form="event-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <time-block-dialog
      ref="timeBlockDialog"
      :saveTimeBlock="saveTimeBlock"
    ></time-block-dialog>
  </div>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";
import TimeBlockDialog from "./TimeBlockDialog.vue";

export default {
  components: {
    Tiptap,
    TimeBlockDialog,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      event: {},
      fields: {
        event_type: null,
        name: null,
        slug: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        location: null,
        date_time_text: null,
        is_featured: false,
        is_ticketed: false,
        is_paid: false,
        short_description: "",
        description: null,
        has_allocation: true,
        allocation: null,
        ticket_price_under_3: null,
        ticket_price_over_3: null,
        ticket_price: null,
        main_image: null,
        time_blocks: [],
        draft: true,
      },
      types: [
        {
          text: "Free",
          value: "free",
        },
        {
          text: "Paid",
          value: "paid",
        },
        {
          text: "Christmas",
          value: "christmas",
        },
      ],
      errors: {},
    };
  },

  methods: {
    open(event = null) {
      if (event !== null) {
        this.event = event;
        this.isEditing = true;
        this.fields.event_type = event.event.type;
        this.fields.name = event.name;
        this.fields.slug = event.slug;
        this.fields.start_date = event.start_date.substr(0, 10);
        this.fields.start_time = event.start_time;
        this.fields.end_date = event.end_date.substr(0, 10);
        this.fields.end_time = event.end_time;
        this.fields.location = event.location;
        this.fields.date_time_text = event.date_time_text;
        this.fields.is_featured = event.is_featured;
        this.fields.is_ticketed = event.is_ticketed;
        this.fields.is_paid = event.is_paid;
        this.fields.description = event.description;
        this.fields.draft = event.draft;

        this.fields.short_description = event.short_description || "";

        if (event.event.type === "christmas") {
          this.fields.ticket_price = event.event.ticket_price;
        } else {
          if (event.is_ticketed) {
            this.fields.allocation = event.event.allocation;
          }

          if (event.is_paid) {
            this.fields.ticket_price_under_3 = event.event.ticket_price_under_3;
            this.fields.ticket_price_over_3 = event.event.ticket_price_over_3;
          }
        }

        if (event.event.type === "christmas") {
          if (event.event.time_blocks.length > 0) {
            this.fields.time_blocks = event.event.time_blocks.map(
              (time_block) => {
                return {
                  id: time_block.id,
                  date: time_block.date,
                  start_time: time_block.start_time,
                  end_time: time_block.end_time,
                  allocation: time_block.allocation,
                };
              }
            );
          }
        }
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.event = {};
      this.fields = {
        event_type: null,
        name: null,
        slug: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        location: null,
        date_time_text: null,
        is_featured: false,
        is_ticketed: false,
        is_paid: false,
        description: null,
        short_description: "",
        has_allocation: true,
        allocation: null,
        ticket_price_under_3: null,
        ticket_price_over_3: null,
        main_image: null,
        time_blocks: [],
        draft: true,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.eventId = this.event.id;
      }

      this.$store
        .dispatch("craigtoun/eventsStore/saveEvent", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    saveTimeBlock(fields, isEditing, key) {
      if (isEditing) {
        this.fields.time_blocks[key].date = fields.date;
        this.fields.time_blocks[key].start_time = fields.start_time;
        this.fields.time_blocks[key].end_time = fields.end_time;
        this.fields.time_blocks[key].allocation = fields.allocation;
      } else {
        this.fields.time_blocks.push(fields);
      }
    },

    deleteTimeBlock(key) {
      this.fields.time_blocks.splice(key, 1);
    },
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.name;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },
};
</script>
